import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import SplashParallaxSection from '@app/components/splash/SplashParallaxSection';
import { SplashCopy } from '@app/components/splash/SplashStyledComponents';
import TranslateHtml from '@app/components/TranslateHtml';

type SplashCommunitySectionProps = {
  imageFilename: string;
};

const SplashCommunitySection = ({
  imageFilename,
}: SplashCommunitySectionProps) => {
  const { t } = useTranslation('splash');
  return (
    <SplashParallaxSection imageFilename={imageFilename}>
      <Content>
        <SplashCommunityCopy>
          <TranslateHtml t={t} i18nKey="splash:splash.community" />
        </SplashCommunityCopy>
      </Content>
    </SplashParallaxSection>
  );
};

const Content = styled.div`
  position: relative;
  height: 325px;
  padding: 30px 0;
  display: flex;
  align-items: flex-end;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 30px 0;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: 540px;
    padding: 50px 0;
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    height: 660px;
    padding: 70px 0;
  }
`;

const SplashCommunityCopy = styled(SplashCopy)`
  color: ${({ theme }) => theme.color.white};
  & br {
    display: none;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    & br {
      display: block;
    }
  }
`;

export default SplashCommunitySection;
