import { useEffect, useState } from 'react';

import {
  ResponsiveSplashAssetUrls,
  SplashAssets,
} from '@app/api/resources/AppConfig';

import { doesBrowserSupportH265 } from '@app/api/services/video-codec-support';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import BackgroundVideo from '@app/components/BackgroundVideo';
import ValueAtBreakpoint from '@app/components/breakpoints/ValueAtBreakpoint';

type SplashVideoProps = {
  splashVideos: SplashAssets['video'];
  fallbackImages?: ResponsiveSplashAssetUrls;
};

const SplashVideo = ({ splashVideos, fallbackImages }: SplashVideoProps) => {
  const [isPaused, setIsPaused] = useState(false);
  const [splashVideosToShow, setSplashVideosToShow] =
    useState<ResponsiveSplashAssetUrls>();

  useEffect(() => {
    if (!splashVideosToShow) {
      if (splashVideos?.h265 && doesBrowserSupportH265()) {
        setSplashVideosToShow(splashVideos.h265);
      } else if (splashVideos?.h264) {
        setSplashVideosToShow(splashVideos.h264);
      } else if (splashVideos) {
        setSplashVideosToShow(splashVideos);
      }
    }
  }, [splashVideos]);

  const showOnboardingModal = useAppSelector(
    state => state.appState.showOnboardingModal,
  );

  useEffect(() => {
    setIsPaused(showOnboardingModal);
  }, [showOnboardingModal]);

  if (!splashVideosToShow) {
    return null;
  }

  return (
    <ValueAtBreakpoint
      defaultValue={false}
      valueAtMobile="mobile_url"
      valueAtTablet="tablet_url"
      valueAtDesktop="desktop_url"
      valueAtWide="desktop_url"
    >
      {({ currentValue: breakpointUrl }) =>
        breakpointUrl && (
          <BackgroundVideo
            isPaused={isPaused}
            src={splashVideosToShow[breakpointUrl as string]}
            fallback={fallbackImages?.[breakpointUrl as string]}
          />
        )
      }
    </ValueAtBreakpoint>
  );
};

export default SplashVideo;
