import { ReactNode, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { ParallaxBanner } from 'react-scroll-parallax';

import ValueAtBreakpoint from '@app/components/breakpoints/ValueAtBreakpoint';
import PageSection from '@app/components/layout/PageSection';

const getParallaxBannerLayers = (imageFilename, size, showImage) => [
  {
    image:
      size && showImage
        ? `https://images.mubicdn.net/splash/${imageFilename}?size=${size}`
        : null,
    speed: -15,
  },
];

type SplashParallaxSectionProps = {
  imageFilename: string;
  children?: ReactNode;
};

const SplashParallaxSection = ({
  imageFilename,
  children = null,
}: SplashParallaxSectionProps) => {
  const [showImage, setShowImage] = useState(false);
  // once we've loaded the image, don't bother hiding again - causes whitespace when scrolling quickly
  const onVisibilityChange = (isVisible: boolean) =>
    isVisible && setShowImage(true);

  return (
    <InView onChange={onVisibilityChange} rootMargin="500px 0px 500px 0px">
      <ValueAtBreakpoint
        defaultValue={false}
        valueAtMobile="800x1000"
        valueAtTablet="1600x1000"
        valueAtDesktop="1600x1000"
        valueAtWide="1600x1000"
      >
        {({ currentValue: size }) => (
          <ParallaxBanner
            layers={getParallaxBannerLayers(imageFilename, size, showImage)}
          >
            {children && <PageSection>{children}</PageSection>}
          </ParallaxBanner>
        )}
      </ValueAtBreakpoint>
    </InView>
  );
};

export default SplashParallaxSection;
