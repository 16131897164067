import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { DevicesIcon } from '@app/components/icons/DevicesIcons';
import SplashParallaxSection from '@app/components/splash/SplashParallaxSection';
import { SplashCopy } from '@app/components/splash/SplashStyledComponents';
import TranslateHtml from '@app/components/TranslateHtml';

type SplashDevicesSectionProps = {
  imageFilename: string;
};

const SplashDevicesSection = ({ imageFilename }: SplashDevicesSectionProps) => {
  const { t } = useTranslation('splash');
  return (
    <SplashParallaxSection imageFilename={imageFilename}>
      <Content>
        <Copy>
          <TranslateHtml
            t={t}
            i18nKey="splash:splash.devices"
            breakNode={<MobileAndWidescreenBreak />}
          />
        </Copy>
        <DevicesIconContainer>
          <DevicesIcon width="100%" />
        </DevicesIconContainer>
      </Content>
    </SplashParallaxSection>
  );
};

const Content = styled.div`
  position: relative;
  padding: 42px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 100px 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding: 170px 0;
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    padding: 230px 0;
  }
`;

const Copy = styled(SplashCopy)`
  color: white;
`;

const DevicesIconContainer = styled.div`
  width: 294px;
  flex-shrink: 0;
  margin-top: 43px;
  align-self: flex-end;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 0;
    margin-left: 35px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 530px;
    margin-left: 50px;
  }
`;

const MobileAndWidescreenBreak = styled.br`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: none;
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    display: block;
  }
`;

export default SplashDevicesSection;
