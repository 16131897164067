import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import { MubiLogoIcon } from '@app/components/icons/LogoIcons';
import RotatingFilmTileGroup from '@app/components/RotatingFilmTileGroup';
import TranslateHtml from '@app/components/TranslateHtml';

const FILM_TILE_ASPECT_RATIO = 0.562;

type SplashFilmsSectionProps = {
  shortLogo: boolean;
  name: string;
  films: Film[];
};

const SplashFilmsSection = ({
  films,
  name,
  shortLogo = false,
}: SplashFilmsSectionProps) => {
  const { t } = useTranslation('splash');
  return (
    <Container>
      <Top>
        <LogoPosition>
          <LogoSize shortLogo={shortLogo}>
            <MubiLogoIcon height="100%" />
          </LogoSize>
        </LogoPosition>
        <Copy shortLogo={shortLogo}>
          <TranslateHtml t={t} i18nKey={`splash:splash.channels.${name}`} />
        </Copy>
      </Top>
      <Bottom>
        <RotatingFilmTileGroup films={films} snowplowElement="film_tile">
          {rotatingFilmTile => (
            <FilmTileContainer>{rotatingFilmTile}</FilmTileContainer>
          )}
        </RotatingFilmTileGroup>
      </Bottom>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  padding: 48px 0;
  @media (min-width: ${props => props.theme.mqNew.mobile}) {
    width: 370px;
    margin: 0 auto;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 80px 0;
    width: 750px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding: 80px 0;
    width: 1130px;
  }
`;

const Top = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
    justify-content: center;
  }
`;

const LogoSize = styled.div<{ shortLogo: boolean }>`
  height: ${props => (props.shortLogo ? '60px' : '95px')};
  display: flex;
  justify-content: center;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: ${props => (props.shortLogo ? '78px' : '128px')};
  }
`;

const LogoPosition = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
`;

const Copy = styled.div<{ shortLogo: boolean }>`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.lightText};
  font-weight: bold;
  margin-top: 42px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 50%;
    line-height: 23px;
    margin-top: ${props => (props.shortLogo ? '0' : '20px')};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Bottom = styled.div`
  margin-top: 34px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 80px;
  }
`;

const FilmTileContainer = styled.div`
  /* full width, minus 30px margins */
  width: calc(100vw - 30px);
  height: calc((100vw - 30px) * ${FILM_TILE_ASPECT_RATIO});
  @media (min-width: ${props => props.theme.mqNew.mobile}) {
    width: ${props => props.theme.grid.standardItemWidth}px;
    height: ${props => props.theme.grid.standardFilmTileHeight}px;
  }
  &:not(:last-child) {
    margin-right: ${props => props.theme.grid.standardGridGap}px;
  }
`;

export default SplashFilmsSection;
