import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const BackgroundVideo = ({
  src,
  fallback,
  isPaused,
  loop = true,
  onEnded = () => {},
}: BackgroundVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    // required for autoplay in mobile safari on iOS v14.4 in some cases (fixes it for Maggie anyway!)
    // https://github.com/facebook/react/issues/6544#issuecomment-705702546
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
  }, []);

  useEffect(() => {
    if (isPaused) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    } else if (videoRef.current.paused) {
      playVideo();
    }
  }, [isPaused]);

  const playVideo = async () => {
    try {
      await videoRef.current.play();
    } catch (err) {
      // Ignoring error when browsers prevent auto play from occurring.
    }
  };

  return (
    <Video
      ref={videoRef}
      autoPlay
      loop={loop}
      playsInline
      preload="auto"
      src={src}
      poster={fallback}
      onEnded={onEnded}
    />
  );
};

type BackgroundVideoProps = {
  src: string;
  fallback?: string;
  isPaused?: boolean;
  loop?: boolean;
  onEnded?: () => void;
};

const Video = styled.video`
  background-color: ${props => props.theme.color.black};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default BackgroundVideo;
