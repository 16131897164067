import { ReactNode } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import PageSection from '@app/components/layout/PageSection';
import { SplashCopy } from '@app/components/splash/SplashStyledComponents';
import TranslateHtml from '@app/components/TranslateHtml';

type SplashFooterProps = {
  cta: ReactNode;
  hideHeader?: boolean;
};

const SplashFooter = ({ cta, hideHeader = false }: SplashFooterProps) => {
  const { t } = useTranslation('splash');
  return (
    <Container>
      <PageSection>
        <Content>
          {!hideHeader && <Copy>{t('splash:splash.footer.discover.new')}</Copy>}
          {cta}
          <SmallText>
            <TranslateHtml
              t={t}
              i18nKey="splash:splash.footer.recaptcha.copy"
              i18nVars={{
                privacy_link: (
                  <a href="https://policies.google.com/privacy">
                    {t('splash:splash.footer.recaptcha.privacy')}
                  </a>
                ),
                terms_link: (
                  <a href="https://policies.google.com/terms">
                    {t('splash:splash.footer.recaptcha.terms')}
                  </a>
                ),
              }}
            />
          </SmallText>
        </Content>
      </PageSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 100px 0 30px;
`;

const Content = styled.div``;

const Copy = styled(SplashCopy)`
  margin-bottom: 30px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-bottom: 36px;
  }
`;

export const SplashFooterSignupButton = styled(MubiButton)`
  font-size: 16px;
  padding: 13px 25px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 18px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 24px;
  }
`;

const SmallText = styled.p`
  margin-top: 110px;
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.theme.color.darkGray};
`;

export default SplashFooter;
