import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SplashCopy = styled.div`
  color: ${props => props.theme.color.darkText};
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  text-transform: uppercase;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 24px;
    line-height: 1;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 32px;
  }
`;

export const MoreInfoCopyStyles = props => css`
  color: ${props.theme.color.darkText};
  font-size: 14px;
  @media (min-width: ${props.theme.mqNew.tablet}) {
    font-size: 16px;
  }
`;
