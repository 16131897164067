import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';

import { ObjectOfStrings } from '@app/types/utility-types';

type SocialMetaTagsProps = {
  url?: string;
  title?: string;
  type?: string;
  description?: string;
  image?: string;
  twitter?: boolean;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterCard?: string;
  twitterSite?: string;
  video?: string;
  videoWidth?: string;
  videoHeight?: string;
  customMeta?: ObjectOfStrings;
};

const SocialMetaTags = ({
  title = null,
  type = 'website',
  description = null,
  url = null,
  image = null,
  twitter = false,
  twitterTitle = null,
  twitterDescription = null,
  twitterCard = null,
  twitterSite = '@mubi',
  video = null,
  videoWidth = null,
  videoHeight = null,
  customMeta = null,
}: SocialMetaTagsProps) => {
  const { t } = useTranslation('common');

  return (
    <Head>
      <meta property="fb:app_id" content={process.env.facebookAppId} />
      <meta property="og:site_name" content="MUBI" />
      {title && <meta property="og:title" content={title} />}
      {type && <meta property="og:type" content={type} />}
      {customMeta &&
        Object.entries(customMeta).map(([key, value]) => (
          <meta key={key} property={key} content={value} />
        ))}
      <meta
        property="og:description"
        content={description || t('common:common.seo.generic_description')}
      />
      {url && <meta property="og:url" content={url} />}
      {image && <meta property="og:image" content={image} />}
      {video && (
        <>
          <meta property="og:video" content={video} />
          <meta property="og:video:url" content={video} />
          <meta property="og:video:secure_url" content={video} />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:width" content={videoWidth} />
          <meta property="og:video:height" content={videoHeight} />
        </>
      )}
      {twitter && (
        <>
          {twitterCard && (
            <meta property="twitter:card" content={twitterCard} />
          )}
          {twitterSite && (
            <meta property="twitter:site" content={twitterSite} />
          )}
          {(twitterTitle || title) && (
            <meta property="twitter:title" content={twitterTitle || title} />
          )}
          {(twitterDescription || description) && (
            <meta
              property="twitter:description"
              content={twitterDescription || description}
            />
          )}
          {image && <meta property="twitter:image" content={image} />}
        </>
      )}
      {twitter && video && (
        <>
          <meta property="twitter:player" content={video} />
          <meta property="twitter:player:width" content={videoWidth} />
          <meta property="twitter:player:height" content={videoHeight} />
        </>
      )}
    </Head>
  );
};

export default SocialMetaTags;
