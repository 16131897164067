import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';

type SeoMetaTagsProps = {
  canonicalUrl?: string;
  description?: string;
  image?: string;
  robots?: boolean;
  noFollow?: boolean;
  noIndex?: boolean;
};

const SeoMetaTags = ({
  canonicalUrl = null,
  robots = true,
  noFollow = false,
  noIndex = false,
  description = null,
  image = null,
}: SeoMetaTagsProps) => {
  const { t } = useTranslation('common');

  return (
    <Head>
      <meta
        name="description"
        content={description || t('common:common.seo.generic_description')}
      />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {image && <link rel="image_src" href={image} />}
      {robots && (
        <meta
          content={`${noFollow ? 'nofollow' : 'follow'}, ${
            noIndex ? 'noindex' : 'index'
          }`}
          name="robots"
        />
      )}
    </Head>
  );
};

export default SeoMetaTags;
